<template>
    <Page>
        <template v-slot:title>
            Сохранить изменения
        </template>
        <ActionForm @submit="validate">
            <template v-slot:desc>
                Для сохранения изменений введите код из СМС
            </template>
            <Code
                ref="code"
                type="save-changes"
                :getCode="getCode"
                @ready="submit"
                notInitSend
            />
        </ActionForm>
    </Page>
</template>

<script>
import Page from '@/applications/personal-area/layouts/page/Page'
import ActionForm from '@/templates/action-form/ActionForm'
import Code from '@/components/form/code/Code'
import Store from '@/store'
import changeData from '@/api/changeData'
import {mapGetters} from 'vuex'

export default {
    name: 'SaveChanges',
    computed: {
        ...mapGetters({
            code_hash: ['application/code']
        })
    },
    methods: {
        validate() {
            this.$refs.code.validate()

            this.$refs.code.isValid && this.submit()
        },
        async submit() {
            const { status } = await changeData({
                ...this.$route.params.data,
                code: this.$refs.code.form.code,
                code_hash: this.code_hash,
            })

            if (status !== 'verifyErrorSms') {
                await this.$store.dispatch('application/update')
                this.$store.commit('application/clearCode')
                this.$refs.code.resetTimer()
                this.$router.push({ name: 'PersonalAreaProfile' })
            } else {
                this.$refs.code.validate()
                this.$refs.code.formErrors.code = 'Неверный код'
            }
        },
        async getCode() {
            await changeData(this.$route.params.data)
        }
    },
    components: {
        ActionForm,
        Code,
        Page,
    },
    beforeRouteEnter(to, from, next) {
        const {
            data
        } = to.params

        if (data) {
            next()
        } else {
            Store.commit('application/load', false)
            next({ name: 'PersonalAreaEdit' })
        }
    }
}
</script>
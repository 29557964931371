<template>
    <div class="pa-page">
        <MobileMenu
            v-if="menu"
            @close="menu=false"
        />
        <h1
            v-if="$slots.title"
            class="pa-page__title"
        >
            <slot name="title"/>
            <div class="pa-page__menu">
                <span class="pa-page__burger" @click="menu=true"><span></span></span>
            </div>
        </h1>
        <div class="pa-page__content">
            <slot/>
        </div>
    </div>
</template>

<script>
import './pa-page.scss'

export default {
    name: 'PersonalAreaPage',
    data() {
        return {
            menu: false
        };
    },
    methods: {
        openMenu() {
            this.menu = true;
        },
    },
    components: {
        MobileMenu: () => import('@/components/common/header/MobileMenu'),
    }
}
</script>